<li class="nav-item pcoded-hasmenu" [routerLinkActive]="['active']">
  <a [routerLinkActive]="['active']" href="javascript:" class="nav-link" (click)="navCollapse($event)">
    <span class="pcoded-micon" *ngIf="item.icon">
      <i class="{{ item.icon }}"></i>
    </span>
    <span class="pcoded-mtext">{{ item.title }} </span>
  </a>
  <ul class="pcoded-submenu" [routerLinkActive]="['active']">
    <ng-container *ngFor="let item of item.children">
      <app-nav-group *ngIf="item.type === 'group'" [item]="item"></app-nav-group>
      <app-nav-collapse *ngIf="item.type === 'collapse'" [item]="item"></app-nav-collapse>
      <app-nav-item *ngIf="item.type === 'item'" [item]="item"></app-nav-item>
    </ng-container>
  </ul>
</li>
