import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SelectVarianceById' })
export class SelectVarianceById implements PipeTransform {
    transform(value: any[], var_pk: number): any {
        if (value && value.length > 0) {
            return value.filter(o => {
                return o.var_pk === var_pk;
            }).map(o => o.var_name);
        } else {
            return 'No data found';
        }
    }
}
