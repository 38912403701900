<!-- <div class="m-header"> -->
<!-- <a href="javascript:" class="mobile-menu" id="mobile-collapse" title="Click to Menu Collapse" (click)="navCollapse()"><span></span></a> -->
<!-- <a href="javascript:" class="header-mobile-collapse" id="mobile-collapse1" (click)="this.NavCollapsedMob.emit()"
    ><i class="feather icon-menu"></i
  ></a>
  <a [routerLink]="['/dashboard/analytics']" class="b-brand">
    <img id="main-logo" src="assets/images/logo.png" alt="logo-img" class="logo" />
    <img src="assets/images/logo-icon.png" alt="logo-icon-img" class="logo-thumb" />
  </a>
  <a class="mob-toggler" [ngClass]="{ on: this.menuClass }" href="javascript:" (click)="toggleMobOption()"
    ><i class="feather icon-more-vertical"></i
  ></a> -->
<!-- </div> -->
<div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
  <!-- <app-nav-left class="me-auto"></app-nav-left> -->
  <!-- <app-nav-right class="ms-auto"></app-nav-right> -->
  <div class="row">
    <!-- [ tabs ] start -->
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <ul class="nav nav-tabs mb-3" ngbNav #nav="ngbNav">
            <li class="nav-item" [ngbNavItem]="1">
              <a class="nav-link file-tab" aria-controls="home" aria-selected="true" ngbNavLink>File</a>
              <ng-template ngbNavContent>
                <div style="width: min-content; height: 65px;">
                  <!-- <fa-icon [icon]="faCoffee"></fa-icon> -->
                  <!-- <i class="feather icon-home"></i> -->
                  <!-- <svg-icon src="/assets/icons/Refresh.ico" [svgStyle]="{ 'width.px':90 }"></svg-icon> -->
                  <img class="png-icon" src="/assets/icons/Refresh.ico" style="height: 60px;
                  width: 60px;
                  padding-bottom: 5px;" (click)="signOut()">
                  <!-- <i class="fa fa-refresh"
                    style="width: 100%; text-align: center; font-size: 37px; color: green; margin-bottom: 9px;"></i> -->
                  <span
                    style="text-align: center; width: 100%; display: block; font-size: 14px; font-weight: 500; letter-spacing: 0.8px;">Refresh</span>
                </div>
                <p class="mb-0">
                  <!-- <fa name="fas fa-camera"></fa> -->

                </p>
              </ng-template>
            </li>
            <li class="nav-item" [ngbNavItem]="2">
              <a class="nav-link" aria-controls="home" aria-selected="true" ngbNavLink>Home</a>
              <ng-template ngbNavContent>
                <p class="mb-0">
                </p>
              </ng-template>
            </li>
            <li class="nav-item" [ngbNavItem]="3">
              <a class="nav-link" aria-controls="home" aria-selected="true" ngbNavLink>Tasks</a>
              <ng-template ngbNavContent>
                <p class="mb-0">
                </p>
              </ng-template>
            </li>
            <li class="nav-item" [ngbNavItem]="4">
              <a class="nav-link" aria-controls="home" aria-selected="true" ngbNavLink>Reports</a>
              <ng-template ngbNavContent>
                <p class="mb-0">
                </p>
              </ng-template>
            </li>
            <li class="nav-item" [ngbNavItem]="5">
              <a class="nav-link" aria-controls="home" aria-selected="true" ngbNavLink>Features</a>
              <ng-template ngbNavContent>
                <p class="mb-0">
                </p>
              </ng-template>
            </li>
            <li class="nav-item" [ngbNavItem]="6">
              <a class="nav-link" aria-controls="home" aria-selected="true" ngbNavLink>Dashborad</a>
              <ng-template ngbNavContent>
                <p class="mb-0">
                </p>
              </ng-template>
            </li>
            <li class="nav-item" [ngbNavItem]="7">
              <a class="nav-link" aria-controls="home" aria-selected="true" ngbNavLink>P.O. Request</a>
              <ng-template ngbNavContent>
                <p class="mb-0">

                </p>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>

</div>