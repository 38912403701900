<form class="form-wrapper" id="dialog-house-on-hold" style="height: 100%;overflow: auto;">
    <igx-card>
        <igx-card-header class="igx-card-header">
            {{ cardHeader | titlecase }}
        </igx-card-header>
        <igx-card-content>
            <table class="mt-2 column" style="width: 100%;height: 100%;">
                <tr class="row" *ngIf="type==='householdnote'">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <label igxLabel class="igx-label">Note :</label>
                    </td>
                    <td class="col-sm-5 col-md-5 col-lg-5 col-xl-9">
                        <div mat-dialog-content>
                            <igx-input-group>
                                <textarea igxInput name="txtNote" cols="30" rows="5" [(ngModel)]="notesObj.hhn_note"
                                    [ngModelOptions]="{standalone: true}"></textarea>
                            </igx-input-group>
                        </div>
                        <mat-error *ngIf="notesObj.hhn_note.length < 1">
                            Required
                        </mat-error>
                    </td>
                </tr>
                <tr class="row" *ngIf="type==='housenote'">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <label igxLabel class="igx-label">Note :</label>
                    </td>
                    <td class="col-sm-5 col-md-5 col-lg-5 col-xl-9">
                        <div mat-dialog-content>
                            <igx-input-group>
                                <textarea igxInput name="txtNote" cols="30" rows="5" [(ngModel)]="notesObj.hon_note"
                                    [ngModelOptions]="{standalone: true}"></textarea>
                            </igx-input-group>
                        </div>
                        <mat-error *ngIf="notesObj.hon_note.length < 1">
                            Required
                        </mat-error>
                    </td>
                </tr>
                <tr class="row" *ngIf="type==='creditdays'">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <label igxLabel class="igx-label">Requested Days :</label>
                    </td>
                    <td class="col-sm-5 col-md-5 col-lg-5 col-xl-9">
                        <div mat-dialog-content>
                            <igx-input-group>
                                <input igxInput class="igx-input" [(ngModel)]="notesObj.crd_requesteddays"
                                    [ngModelOptions]="{standalone: true}" name="requested"
                                    type="number" required/>
                            </igx-input-group>
                        </div>
                        <mat-error *ngIf="notesObj.crd_requesteddays < 1 || notesObj.crd_requesteddays === ''">
                            Required
                        </mat-error>
                    </td>
                </tr>
                <tr class="row" *ngIf="type==='creditdays'">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <label igxLabel class="igx-label">Approved Days :</label>
                    </td>
                    <td class="col-sm-5 col-md-5 col-lg-5 col-xl-9">
                        <div mat-dialog-content>
                            <igx-input-group>
                                <input igxInput class="igx-input" [(ngModel)]="notesObj.crd_approveddays"
                                    [ngModelOptions]="{standalone: true}" name="approved"
                                    type="number" required/>
                            </igx-input-group>
                        </div>
                        <mat-error *ngIf="notesObj.crd_approveddays < 1 || notesObj.crd_approveddays === ''">
                            Required
                        </mat-error>
                        <mat-error
                            *ngIf="notesObj.crd_approveddays > 0 && notesObj.crd_requesteddays > 0 && notesObj.crd_requesteddays < notesObj.crd_approveddays">
                            Approved Days can't be greater then requested days.
                        </mat-error>
                    </td>
                </tr>

                <tr class="row">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-3"> </td>
                    <td class="col-sm-4 col-md-4 col-lg-4 col-xl-9">
                        <div mat-dialog-actions class="d-flex justify-content-end button-sample">
                            <button igxButton="flat" igxRipple (click)="saveData()" class="me-2"
                                style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));">Ok</button>
                            <button igxButton="flat" igxRipple (click)="close()"
                                style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1); ">Cancel</button>
                        </div>
                    </td>
                </tr>
            </table>
        </igx-card-content>
    </igx-card>
</form>