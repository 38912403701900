import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SelectWarrantyUserTypeById' })
export class SelectWarrantyUserTypeById implements PipeTransform {
    transform(value: any[], typeId: number): any {
        if (value && value.length > 0) {
            return value.filter(o => {
                return o.id === typeId;
            }).map(o => o.text);
        } else {
            return 'No data found';
        }
    }
}

@Pipe({ name: 'SelectWarrantySignOffById' })
export class SelectWarrantySignOffById implements PipeTransform {
    transform(value: any[], signOffId: number) {
        if (value && value.length > 0) {
            return value.filter(o => {
                return o.id === signOffId;
            }).map(o => o.text);
        } else {
            return 'No data found';
        }
    }
}

@Pipe({ name: 'SelectWarrantyScheduleUserById' })
export class SelectWarrantyScheduleUserById implements PipeTransform {
    transform(value: any[], userId: number) {
        if (value && value.length > 0) {
            return value.filter(o => {
                return o.id === userId;
            }).map(o => o.text);
        } else {
            return 'No data found';
        }
    }
}
