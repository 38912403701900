import { Component, Input, OnInit } from '@angular/core';
import { ToasterService } from '../toaster.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-toaster-container',
    templateUrl: './toaster-container.component.html',
    styles: []
})
// <pre>toast$: {{ this.toaster.toast$ | async | json }}</pre>
// <pre>toasts: {{ toasts | json }}</pre>
export class ToasterContainerComponent implements OnInit {

    toasts: any[] = [];

    private loading = new BehaviorSubject<any[]>([]);
    public toasts$ = this.loading.asObservable();

    constructor(public toaster: ToasterService) { }

    ngOnInit() {
        this.toaster.toast$
            .subscribe((toast: any) => {
                this.toasts = [toast, ...this.loading.value];
                this.loading.next(this.toasts);
                setTimeout(() => this.loading.value.pop(), toast.delay || 6000);
            });
    }

    remove(index: any) {
        this.toasts = this.toasts.filter((v, i) => i !== index);
        this.loading.next(this.toasts);
        this.toasts.splice(index, 1);
    }
}