// Angular Import
import { Component, ViewChild } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';
import { GradientConfig } from 'src/app/app-config';
import { NavigationItem, NavigationItems } from './navigation/navigation';
import { IgxExpansionPanelComponent, IgxNavigationDrawerComponent } from 'igniteui-angular';
import { Globals } from 'src/globals';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { IColumnPipeArgs, IgxDialogComponent } from 'igniteui-angular';
import { MatDialog } from '@angular/material/dialog';
import { TransferHousesComponent } from 'src/app/pages/dialogs/transfer-houses/transfer-houses.component';
import { LocalService } from 'src/app/core/services/local.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  // public props
  navCollapsed: boolean;
  navCollapsedMob: boolean;
  windowWidth: number;
  navigations: NavigationItem[];
  public selected = 'House';
  @ViewChild(IgxNavigationDrawerComponent, { static: true })
  public drawer!: IgxNavigationDrawerComponent;
  isCollapse: boolean = false;
  @ViewChild('transferHouse', { read: IgxDialogComponent, static: true })
  public transferHouse!: IgxDialogComponent;
  public contextmenu = false;
  @ViewChild(IgxExpansionPanelComponent, { read: IgxExpansionPanelComponent, static: true })
  public panel!: IgxExpansionPanelComponent;
  loggedInUser: any = '';
  // constructor
  constructor(
    private location: Location,
    private authenticationService: AuthenticationService,
    private localStorage: LocalService,
    public globals: Globals,
    private authService: MsalService,
    private locationStrategy: LocationStrategy,
    private dialog: MatDialog
  ) {
    if (this.globals.loggedInuser$.value) {
      this.loggedInUser = this.globals.loggedInuser$.value.name;
    }
    else {
      this.checkAndSetActiveAccount();
    }

    this.navigations = this.setNavigationMenu();
    let current_url = this.location.path();
    const baseHref = this.locationStrategy.getBaseHref();
    if (baseHref) {
      current_url = baseHref + this.location.path();
    }

    this.windowWidth = window.innerWidth;

    if (current_url === baseHref + '/layout/collapse-menu' || (this.windowWidth >= 992 && this.windowWidth <= 1024)) {
      // Sumit changes
      GradientConfig.collapse_menu = true;
    }

    this.navCollapsed = this.windowWidth >= 992 ? true : false;
    this.navCollapsedMob = true;
  }
  setNavigationMenu(): any {
    return NavigationItems.filter(nav => {
      let isAllow: any = true;
      if (nav.children) {
        let childAllow: any = true;
        nav.children = nav.children.filter(childNav => {
          if (childNav.policy && !this.authenticationService.hasPermission(nav.policy)) {
            childAllow = false;
          }
          return childAllow;
        });
        if (!nav.children || nav.children.length < 1) {
          isAllow = false;
        }
      }
      else if (nav.policy && !this.authenticationService.hasPermission(nav.policy)) {
        isAllow = false;
      }
      return isAllow;
    });
  }
  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    this.globals.loggedInuser$.next(activeAccount);
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.globals.loggedInuser$.next(accounts);
      this.authService.instance.setActiveAccount(accounts[0]);
    }
    this.loggedInUser = this.globals.loggedInuser$.value.name;
  }
  // public method
  navMobClick() {
    if (this.windowWidth < 992) {
      if (this.navCollapsedMob && !document.querySelector('app-navigation.pcoded-navbar')?.classList.contains('mob-open')) {
        // Sumit changes
        this.navCollapsedMob = !this.navCollapsedMob;
        setTimeout(() => {
          // Sumit changes
          this.navCollapsedMob = !this.navCollapsedMob;
        }, 100);
      } else {
        // Sumit changes
        this.navCollapsedMob = !this.navCollapsedMob;
      }
    }
  }
  public navigate(item: any) {
    this.selected = item.title;
    if (document.getElementsByTagName('igx-nav-drawer')[0].scrollWidth === 68) {
      this.drawer.open();
    }
    else {
      // this.drawer.close();
    }
  }
  openPopup(item: any) {
    // if (item.url === 'tranferhouse') {
    if (item.id === 'transfer-houses') {
      this.contextmenu = false;
      const dialogRef = this.dialog.open(TransferHousesComponent, {
        disableClose: true,
      });
    }
  }
  logOut(): any {
    this.authenticationService.signOut();
    this.localStorage.removeData('idToken');
  }
}
