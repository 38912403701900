<div class="admin-top-content" *ngIf="!loginDisplay">
  <div>
    <a href="/">
      <img class="png-icon" src='{{ "assets/icons/SAMLogoTransparent.png" }}'>
    </a>
  </div>
  <button mat-raised-button class="login-button" (click)="loginRedirect()">Login</button>
</div>
<!-- <button mat-raised-button class="login-button" *ngIf="loginDisplay" (click)="logout()">Logout</button> -->
<a class="login-button" (click)="logout()" *ngIf="loginDisplay">
  <span class="pcoded-micon">
    <img class="png-icon" src='assets/icons/right-from-bracket-solid.svg' style="height: 30px;width: 30px;"></span>
</a>
<app-toaster-container class="toaster-wrapper"></app-toaster-container>
<router-outlet *ngIf="loginDisplay">
  <!-- <app-spinner></app-spinner> -->
  <loading></loading>

</router-outlet>