<li [ngClass]="item.classes" *ngIf="item.url && !item.external" [routerLinkActive]="['active']" class="li-wrapper">
  <!-- <a class="nav-link menu-anchor" [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]"
    (click)="closeOtherMenu($event)" *ngIf="item.id !== 'logOut'">
    <span *ngIf="item.iconType==='image'" class="pcoded-micon">
      <img class="png-icon" src='{{ "assets/icons/" + item.icon }}' style="height: 35px;
      width: 35px;
      padding-bottom: 0px;"></span>
    <span *ngIf="item.iconType==='font-awesome'" class="pcoded-micon"><i [ngClass]="item.icon"></i></span>
    <span class="pcoded-mtext" style="line-height: 46px;" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
    <ng-template #directTitle>
      {{ item.title }}
    </ng-template>
  </a>
  <a class="nav-link menu-anchor" [target]="item.target ? '_blank' : '_self'" (click)="logOut()"
    *ngIf="item.id === 'logOut'">
    <span *ngIf="item.iconType==='image'" class="pcoded-micon">
      <img class="png-icon" src='{{ "assets/icons/" + item.icon }}' style="height: 35px;
      width: 35px;
      padding-bottom: 0px;"></span>
    <span *ngIf="item.iconType==='font-awesome'" class="pcoded-micon"><i [ngClass]="item.icon"></i></span>
    <span class="pcoded-mtext" style="line-height: 46px;" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
    <ng-template #directTitle>
      {{ item.title }}
    </ng-template>
  </a> -->
</li>
<li [ngClass]="item.classes" *ngIf="item.url && item.external">
  <!-- <a [target]="item.target ? '_blank' : '_self'" (click)="logOut()" class="menu-anchor" *ngIf="item.id === 'logOut'">
    <span *ngIf="item.iconType==='image'" class="pcoded-micon">
      <img class="png-icon" src='{{ "assets/icons/" + item.icon }}' style="height: 60px;
      width: 60px;
      padding-bottom: 5px;"></span>
    <span *ngIf="item.iconType==='font-awesome'" class="pcoded-micon"><i [ngClass]="item.icon"></i></span>
    <span class="pcoded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
    <ng-template #directTitle>
      {{ item.title }}
    </ng-template>
  </a>
  <a [target]="item.target ? '_blank' : '_self'" [href]="item.url" class="menu-anchor" *ngIf="item.id !== 'logOut'"> 
    <span *ngIf="item.iconType==='image'" class="pcoded-micon">
      <img class="png-icon" src='{{ "assets/icons/" + item.icon }}' style="height: 60px;
      width: 60px;
      padding-bottom: 5px;"></span>
    <span *ngIf="item.iconType==='font-awesome'" class="pcoded-micon"><i [ngClass]="item.icon"></i></span>
    <span class="pcoded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
    <ng-template #directTitle>
      {{ item.title }}
    </ng-template>
  </a> -->
</li>