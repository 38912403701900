export class GradientConfig {
  static layout = 'vertical'; // vertical, horizontal
  static subLayout = ''; // horizontal-2
  static collapse_menu = false; // true
  static isDarkLayout = false; // true
  static header_back_color = 'header-blue'; // header-blue, header-red, header-purple, header-info, header-dark, header-orange, header-green, header-yellow, header-orchidGreen, header-indigoGreen, header-darkGreen, header-darkblue
  static isRtlLayout = false; // true
  static nav_fixed_layout = false; // false
  static header_fixed_layout = true; // false
  static box_layout = false; // true
}
