<igx-card-header>
    <h5 igxCardHeaderSubtitle>{{message}}</h5>
</igx-card-header>
<!-- <form class="form-wrapper">
    <table class="mt-2 column" style="width: 100%;height: 100%;">
        <div>
            <ul *ngFor="let hou of cellData">
                <li>{{hou.title}}</li>
            </ul>
        </div>
    </table>
</form> -->
<div igxDialogActions class="dialog-container dialog-actions">
    <button igxButton="flat" igxRipple (click)="confirm()"
        style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);">Ok</button>
</div>