<div (clickOutside)="navMob()" class="navbar-content-wrapper">
  <div class="navbar-content">
    <div class="nav-bar-top-content" style="overflow: auto;">
      <nav class="nav flex-column">
        <div class="container-fluid" (click)="activeMenu='activity'" [ngClass]="activeMenu=='activity' ? 'active' : ''">
          <a routerLink="/system-management/activity"> Activities </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/city"> City </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/company"> Company </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/company-option"> Company Option </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/costcode"> Cost Code </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/division"> Division </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/form"> Form </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/garage-types"> Garage Type </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/group"> Group </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/holiday"> Holiday </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/option-code"> Option Code </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/phase"> Phase </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/plan"> Plan </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/poi-activity"> POI Activities </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/poi-group"> POI Groups </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/room"> Rooms </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/subdivision"> Subdivisions </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/template"> Templates </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/user"> Users </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/variance"> Variance </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/vendor-type"> Vendor Types </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/vendor-price"> Vendor Price </a>
        </div>
        <div class="container-fluid">
          <a routerLink="/system-management/warranty-time-window"> Warranty Time Window </a>
        </div>
      </nav>
    </div>
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
      <ng-container *ngFor="let item of navigations" class="main-group-wrapper">
        <app-nav-group *ngIf="item.type === 'group'" [item]="item"></app-nav-group>
        <app-nav-collapse *ngIf="item.type === 'collapse'" [item]="item"></app-nav-collapse>
        <app-nav-item *ngIf="item.type === 'item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
    <!-- <app-card [hidHeader]="true" cardClass="text-center">
      <i class="feather icon-sunset f-40"></i>
      <h6 class="mt-3">Help?</h6>
      <p>Please contact us on our email for need any support</p>
      <a href="javascript:" target="_blank" class="btn btn-primary btn-sm text-white m-0">Support</a>
    </app-card> -->
    <!-- <div class="version">
      <label disabled class="pe-auto">v{{ currentApplicationVersion }}</label>
    </div> -->
  </div>
</div>