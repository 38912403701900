import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent {

  public setPasswordForm: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SetPasswordComponent>
  ) {

    this.setPasswordForm = new FormGroup({
      cmbCompanyList: new FormControl(0, [Validators.required])
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.setPasswordForm.controls[controlName].hasError(errorName);
  }
  getData(): void {
    if (this.setPasswordForm.valid) {
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
