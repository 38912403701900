import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SelectContactByContactPk' })
export class SelectContactByContactPk implements PipeTransform {
  transform(value: any[], contact: number): any {
    if (value && value.length > 0) {
      return value.filter(o => {
        return o.con_pk === contact;
      }).map(o => o.con_name);
    } else {
      return 'No data found';
    }
  }
}
