import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        // todo: retry request without custom headers (copy issue)
        // retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMsg = 'Unknown error.';
          if (error.error instanceof ErrorEvent) {
            // client side error
            console.log('this is client side error');
            errorMsg = `Error: ${error.error.message}`;
          } else if (error.error && error.error.message) {
            if (error.error.code === '409' || error.error.code === '1501') {
              console.log('Entity with the specified id already exists in the system.');
              errorMsg = `Entity with the specified id already exists in the system.`;
            } else if (error.error.code === 1501) {
              console.log(error.error.meaning);
              errorMsg = error.error.meaning;
            } else {
              // server side error
              console.log('this is server side error');
              errorMsg = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
          } else {
            // server side error
            console.log('this is server side error');
            errorMsg = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          return throwError(errorMsg);
        })
      );
  }
}
