import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent {
  message: string = '';
  cellData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationBoxComponent>
  ) {
    this.cellData = data.cellData;
    this.message = data.message;
  }
  confirm(): void {
    this.dialogRef.close({ ...this.cellData });
  }
  close(): void {
    this.dialogRef.close();
  }
}
