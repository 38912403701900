import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class RoleService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('role');
    }
    getByUser(user: any): Observable<any> {
        return this.http.get(`${this.path}?action=user&id=${user}`);
    }
    getByLoggedInUser(): Observable<any> {
        return this.http.get(`${this.path}?action=loginuser`);
    }
}