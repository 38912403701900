import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'SelectCompanyById' })
export class SelectCompanyById implements PipeTransform {
    transform(value: any[], companyId: number): any {
        if (value && value.length > 0) {
            return value.filter(o => {
                return o.com_pk === companyId;
            }).map(o => o.com_name);
        } else {
            return 'No data found';
        }
    }
}