import { throwError } from 'rxjs';
import { BackendService } from './backend.service';

export interface Resource {
  id: number;
}

export class ApiService<T extends Resource> extends BackendService {
  constructor(endpoint: string) {
    super(endpoint);
  }

  protected onSuccess(res: any) {
    return res || {};
  }
  protected onSuccessPdf(res: any) {
    return res['data'] || {};
  }
  protected onError(error: any) {
    const errMsg = (error.message) ? error.message :
      error._body ? error._body :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';

    console.error(errMsg);
    return throwError(errMsg);
  }
  // create(item: T): Observable<T> {
  //   return this.http.post<T>(this.path, item);
  // }

  // update(id: any, item: T): Observable<T> {   
  //   return this.http.put<T>(`${this.path}/${id}`, item);
  // }

  // read(id: number): Observable<T> {
  //   return this.http.get<T>(`${this.path}/${id}`);
  // }

  // list(
  //   whereCondition = '',
  //   sortOrder = '',
  //   pageNumber = 1,
  //   pageSize = 100
  // ): Observable<T[]> {    
  //   return this.http.get<T[]>(this.path, {
  //     params: new HttpParams()
  //       .set('whereCondition', encodeURIComponent(whereCondition.toString()))
  //       .set('orderBy', encodeURIComponent(sortOrder.toString()))
  //       .set('page', pageNumber.toString())
  //       .set('size', pageSize.toString())
  //   });
  // }  
  // delete(id: number): Observable<T> {
  //   return this.http.delete<T>(`${this.path}/${id}`);
  // }
}
