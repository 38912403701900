import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IgxComboComponent } from 'igniteui-angular';
import { reject } from 'lodash';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { HouseService } from 'src/app/core/services/house.service';
import { LoadingService } from 'src/app/core/services/loader.service';
import { ReportsService } from 'src/app/core/services/reports.service';
import { UsersService } from 'src/app/core/services/user.service';
import { ToasterService } from 'src/app/toaster/toaster.service';

@Component({
  selector: 'app-transfer-houses',
  templateUrl: './transfer-houses.component.html',
  styleUrls: ['./transfer-houses.component.scss']
})
export class TransferHousesComponent {
  @ViewChild('houseName', { read: IgxComboComponent, static: false }) public combo!: IgxComboComponent;

  //#region Arrays and Objects
  public builderListFrom: any[] = [];
  public builderListTo: any[] = [];
  public companyList: any = [];
  public houseList: any[] = [];
  public lstHouses: any = [];
  public lstbuilder: any = [];
  public selectedHouse: any[] = [];
  public transferHouseForm: FormGroup;
  trackProcess: any = {
    totalProcess: 0,
    completedProcess: 0
  }
  //#endregion

  //#region Variables
  isAdministrator: boolean = false;
  isManagement: boolean = false;
  isWarrantyManager: boolean = false;
  isAssistantBuilder: boolean = false;
  isDesigner: boolean = false;
  builderFrom: number = 0;
  company: number = 0;
  //#endregion

  constructor(private dialogRef: MatDialogRef<TransferHousesComponent>,
    private userService: UsersService,
    private loaderService: LoadingService,
    private companyService: CompanyService,
    private reportService: ReportsService,
    private authenticationService: AuthenticationService,
    private houseService: HouseService,
    private toasterService: ToasterService) {
    this.transferHouseForm = new FormGroup({
      builderFrom: new FormControl('', [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      houseName: new FormControl('', [Validators.required]),
      builderTo: new FormControl('', [Validators.required])
    });
    this.trackProcess = {
      totalProcess: 0,
      completedProcess: 0
    }
    this.authenticationService.allRole().then(value => {
      this.loaderService.show();
      this.isAdministrator = value.Administrator;
      this.isManagement = value.Management;
      this.isWarrantyManager = value['Warranty Manager'];
      this.isAssistantBuilder = value['Assistant Builder'];
      this.isDesigner = value.Designer;
      this.getHouses();
      this.getBuilder();
      // this.getCompany();
    }, error => {
      this.loaderService.hide(0);
    });
  }

  getBuilder() {
    this.processStart();
    this.loaderService.show();
    this.userService.getAll('builderbyuser').subscribe(result => {
      const unique = result.filter((a: any, i: any) => result.findIndex((s: any) => a.usr_pk === s.usr_pk && a.usr_active === true) === i);
      this.lstbuilder = unique;
      this.builderListFrom = unique.sort((a: any, b: any) => a.usr_name.localeCompare(b.usr_name));
      this.loaderService.hide(0);
      this.processEnd();
    }, error => {
      this.processEnd(true);
    })
  }
  getCompany(): any {
    this.processStart();
    this.companyService.getAll().subscribe((res: any) => {
      this.companyList = res.sort((a: any, b: any) => a.com_name.localeCompare(b.com_name));
      this.transferHouseForm.controls['companyName'].setValue(this.companyList[0].com_pk);
      this.processEnd();
    }, (error: any) => {
      this.processEnd(true);
    });
  }
  getHouses(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.isAdministrator || this.isManagement) {
        this.processStart();
        this.loaderService.show();
        this.reportService.getMilestoneBySupervisorReport().subscribe(result => {
          this.lstHouses = result;
          // this.loaderService.hide(0);
          resolve(true);
          this.processEnd();
        }, error => {
          this.processEnd(true);
          // this.loaderService.hide(0);
        });
      } else if (this.isWarrantyManager) {
        this.processStart();
        // this.loaderService.show();
        this.reportService.getMilestoneByWarrantyManagerReport().subscribe(result => {
          this.lstHouses = result;
          this.processEnd();
          // this.loaderService.hide(0);
          resolve(true);
        }, error => {
          this.processEnd(true);
          // this.loaderService.hide(0);
        });
      } else if (this.isAssistantBuilder) {
        this.processStart();
        // this.loaderService.show();
        this.reportService.getMilestoneByAssistedBuilderReport().subscribe(result => {
          this.lstHouses = result;
          this.processEnd();
          // this.loaderService.hide(0);
          resolve(true);
        }, error => {
          this.processEnd(true);
          // this.loaderService.hide(0);
        });
      } else if (this.isDesigner) {
        this.processStart();
        // this.loaderService.show();
        this.reportService.getMilestoneByDesignerReport().subscribe(result => {
          this.lstHouses = result;
          this.processEnd();
          // this.loaderService.hide(0);
          resolve(true);
        }, error => {
          this.processEnd(true);
          // this.loaderService.hide(0);
        });
      } else {
        this.processStart();
        // this.loaderService.show();
        this.reportService.getMilestoneByBuilderReport().subscribe(result => {
          this.lstHouses = result;
          this.processEnd();
          // this.loaderService.hide(0);
          resolve(true);
        }, error => {
          this.processEnd(true);
          // this.loaderService.hide(0);
        });
      }
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.transferHouseForm.controls[controlName].hasError(errorName);
  }

  close(): void {
    this.dialogRef.close();
  }
  changeSelection(event: any) {
    this.processStart();
    if (event.owner.id === 'builderFrom') {
      this.company = 0;
      this.houseList = [];
      this.selectedHouse = [];
      this.builderFrom = event.newSelection;
      if (this.builderFrom > 0) {
        this.builderListTo = this.lstbuilder.filter((x: any) => x.usr_pk !== this.builderFrom);
      }
      this.getCompany();
      this.processEnd();
    } else if (event.owner.id === 'companyName') {
      this.company = event.newSelection;
      this.processEnd();
    }
    if (this.builderFrom > 0 && this.company > 0) {
      this.processStart()
      this.houseList = [];
      this.houseList = this.lstHouses.filter((x: any) => x.hou_usrfk === this.builderFrom && x.hou_comfk === this.company && x.mil_M11 === null);
      this.processEnd();
    }
  }
  transferHouses() {
    if (this.transferHouseForm.valid) {
      this.loaderService.show();
      const toBuilder = this.transferHouseForm.controls['builderTo'].value;
      this.houseService.transferHouse(toBuilder, this.selectedHouse).subscribe(result => {
        this.dialogRef.close();
        this.toasterService.show('success', '', 'The selected house(s) transfer sucessfully');
        this.loaderService.hide(0);
      }, error => {
        this.loaderService.hide(0);
        this.toasterService.show('error', '', 'Something went wrong');
      });
    }
  }

  public get allSelected(): boolean {
    return this.combo ? this.combo.selection.length === this.combo.data!.length : false;
  }
  public handleSelect(event: any) {
    if (this.allSelected) {
      this.combo.deselectAllItems();
    } else {
      this.combo.selectAllItems();
    }
  }

  processStart() {
    this.trackProcess.totalProcess = this.trackProcess.totalProcess + 1;
  }
  processEnd(isFailed: any = false) {
    this.trackProcess.completedProcess = this.trackProcess.completedProcess + 1;
    this.checkComplte(isFailed);
  }
  checkComplte(isFailed: any = false) {
    if (this.trackProcess.completedProcess === this.trackProcess.totalProcess) {
      if (!isFailed) {
        this.loaderService.hide(0);
      }
      else {
        this.loaderService.hide(0);
        this.toasterService.show('error', '', 'Something went to wrong.');
      }
    }
  }
}
