<igx-card-header class="dialog-header">
    {{isModify ? 'Update': 'Add'}} PO Request
</igx-card-header>
<button igxButton="flat" class="col-auto igx-primary-btn close-button" (click)="closeDialog()">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<!-- <button mat-icon-button class="close-button" (click)="closeDialog()">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button> -->
<div id="add-vendor" (window:resize)="onResize($event)" class="p-2" style="height: calc(100% - 120px);">
    <div style="width: 100%;display: flex;">
        <div style="padding: 10px;width: 100%;">
            <div class="button-sample" style="padding: 0 5px; float: right">
                <a (click)="save()">
                    <span class="pcoded-micon">
                        <img class="png-icon" src='assets/icons/floppy-disk-solid.svg' style="height: 50px;width: 50px;"
                            matTooltip="Save">
                    </span>
                </a>
            </div>
        </div>
    </div>
    <form class="poReqForm form-wrapper" #parentForm="ngForm">
        <igx-card>
            <igx-card-header class="igx-card-header">
                EPO Form: Parent Form
            </igx-card-header>
            <igx-card-content class="gradiant">
                <fieldset [disabled]="!(checkoutUser
                || isAdministrator || isAssistanceBuilder) || permission.poRequest < rolesEnum.ModifyOnly">
                    <table class="mt-2 column" style="width: 100%;height: 100%;">
                        <tr class="row">
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">Completed?:</label>
                                <igx-checkbox [(ngModel)]="poRequest.por_iscompleted" #isCompleted="ngModel"
                                    name="isComleted"
                                    [disabled]="!poRequest.por_approve || poRequest.por_po === '' || house.hou_payrollcompleted">
                                </igx-checkbox>
                            </td>
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">Urgent:</label>
                                <igx-checkbox #urgent="ngModel" name="urgent" [(ngModel)]="poRequest.por_urgentpo">
                                </igx-checkbox>
                            </td>
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">Resubmit?:</label>
                                <igx-checkbox [(ngModel)]="!poRequest.por_isreject" #isRejected="ngModel"
                                    name="isRejected"
                                    [disabled]="poRequest.por_iscompleted || poRequest.por_po === '' || !poRequest.por_isreject">
                                </igx-checkbox>
                            </td>
                        </tr>
                        <tr class="row">
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">Vendor Type:</label>
                                <igx-simple-combo [(ngModel)]="poRequest.por_vetfk" [data]="vendorTypes"
                                    (selectionChanging)="changeVendorType($event)" #vendorType="ngModel"
                                    name="vendorType" [displayKey]="'vet_name'" [valueKey]="'vet_pk'"
                                    placeholder="Select Vendor Type" required>
                                </igx-simple-combo>
                                <div *ngIf="vendorType.invalid && (vendorType.dirty || vendorType.touched)"
                                    class="alert">
                                    <div *ngIf="vendorType.errors?.['required']">Required.
                                    </div>
                                </div>
                            </td>
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">Vendor #:</label>
                                <igx-simple-combo [(ngModel)]="poRequest.por_confk" [data]="poRequestVendor"
                                    (selectionChanging)="changeVendor($event)" #contact="ngModel" name="contact"
                                    [displayKey]="'con_name'" [valueKey]="'con_pk'" required>
                                </igx-simple-combo>
                                <div *ngIf="contact.invalid && (contact.dirty || contact.touched)" class="alert">
                                    <div *ngIf="contact.errors?.['required']">Required.
                                    </div>
                                </div>
                            </td>
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">Purchase Date:</label>
                                <igx-date-picker [(ngModel)]="poRequest.por_date" #purDate="ngModel" name="purDate">
                                </igx-date-picker>
                            </td>
                        </tr>
                        <tr class="row">
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">Variance #:</label>
                                <igx-simple-combo [(ngModel)]="poRequest.por_varfk" [data]="variances"
                                    (selectionChanging)="changeVariance($event)" #variance="ngModel" name="variance"
                                    [displayKey]="'var_name'" [valueKey]="'var_pk'" required>
                                </igx-simple-combo>
                                <div *ngIf="variance.invalid && (variance.dirty || variance.touched)" class="alert">
                                    <div *ngIf="variance.errors?.['required']">Required.
                                    </div>
                                </div>
                            </td>
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">{{hasVariance('W') === true ? 'Warranty #:' : 'Change
                                    Order #:'}}</label>
                                <igx-input-group>
                                    <input igxInput class="igx-input" [(ngModel)]="poRequest.por_eco" #eco="ngModel"
                                        [igxTextSelection]="true" name="Completed" type="text" />
                                </igx-input-group>
                            </td>
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">Vendor Id:</label>
                                <igx-simple-combo [(ngModel)]="poRequest.por_confk" [data]="poRequestVendor"
                                    #vendorId="ngModel" name="vendorId" [displayKey]="'con_id'" [valueKey]="'con_pk'"
                                    [disabled]="true">
                                </igx-simple-combo>
                            </td>
                        </tr>
                        <tr class="row">
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">Cost Code Description:</label>
                                <igx-simple-combo [(ngModel)]="poRequest.por_ccdfk" [data]="poRequestCostcodes"
                                    #ccdDesc="ngModel" name="ccdDesc" [displayKey]="'ccd_name'" [valueKey]="'ccd_pk'"
                                    required>
                                </igx-simple-combo>
                                <div *ngIf="ccdDesc.invalid && (ccdDesc.dirty || ccdDesc.touched)" class="alert">
                                    <div *ngIf="ccdDesc.errors?.['required']">Required.
                                    </div>
                                </div>
                            </td>
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">Cost Code:</label>
                                <igx-simple-combo [(ngModel)]="poRequest.por_ccdfk" [data]="poRequestCostcodes"
                                    #ccdId="ngModel" name="ccdId" [displayKey]="'ccd_id'" [valueKey]="'ccd_pk'">
                                </igx-simple-combo>
                                <div *ngIf="ccdId.invalid && (ccdId.dirty || ccdId.touched)" class="alert">
                                    <div *ngIf="ccdId.errors?.['required']">Required.
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="row">
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">P.O #:</label>
                                <igx-input-group>
                                    <input igxInput class="igx-input" [(ngModel)]="poRequest.por_po" #po="ngModel"
                                        [igxTextSelection]="true" name="po" type="text" required />
                                </igx-input-group>
                                <div *ngIf="po.invalid && (po.dirty || po.touched)" class="alert">
                                    <div *ngIf="po.errors?.['required']">Required.
                                    </div>
                                </div>
                            </td>
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">P.O Admin:</label>
                                <igx-input-group>
                                    <input igxInput class="igx-input" [(ngModel)]="poRequest.con_name"
                                        #poAdmin="ngModel" [igxTextSelection]="true" name="poAdmin" type="text"
                                        [readonly]="true" />
                                </igx-input-group>
                            </td>
                        </tr>
                        <tr class="row">
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">Amount:</label>
                                <igx-input-group>
                                    <input igxInput class="igx-input" [(ngModel)]="poRequest.por_amount"
                                        #amount="ngModel" [igxTextSelection]="true" name="amount" type="number"
                                        required />
                                </igx-input-group>
                                <div *ngIf="amount.invalid && (amount.dirty || amount.touched)" class="alert">
                                    <div *ngIf="amount.errors?.['required']">Required.
                                    </div>
                                </div>
                            </td>
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <label igxLabel class="igx-label">Vendor Invoice #:</label>
                                <igx-input-group>
                                    <input igxInput class="igx-input" [(ngModel)]="poRequest.por_invoice"
                                        #vendorInvoice="ngModel" [igxTextSelection]="true" name="vendorInvoice"
                                        type="text" />
                                </igx-input-group>
                            </td>
                        </tr>
                        <tr class="row">
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <label igxLabel class="igx-label">Reject Reason:</label>
                                <igx-input-group>
                                    <input igxInput class="igx-input" [(ngModel)]="poRequest.por_rejectreason"
                                        #rejectedreason="ngModel" [igxTextSelection]="true" name="rejectedreason"
                                        type="text" />
                                </igx-input-group>
                                <div *ngIf="poRequest.por_isreject === true && poRequest.por_rejectreason === ''"
                                    class="alert">
                                    <div>Required.</div>
                                </div>
                            </td>
                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <label igxLabel class="igx-label">Purchase Coordinator:</label>
                                <igx-input-group>
                                    <input igxInput class="igx-input" [(ngModel)]="poRequest.por_purchase_comments"
                                        #purComment="ngModel" [igxTextSelection]="true" name="purComment" type="text" />
                                </igx-input-group>
                            </td>
                        </tr>
                        <tr class="row">
                            <td class="col-sm-12 col-md-5 col-lg-5 col-xl-4">
                                <label igxLabel class="igx-label">Comment:</label>
                                <igx-input-group>
                                    <input igxInput class="igx-input" [(ngModel)]="poRequest.por_comments"
                                        #comment="ngModel" [igxTextSelection]="true" name="comment" type="text"
                                        required />
                                </igx-input-group>
                                <div *ngIf="comment.invalid && (comment.dirty || comment.touched)" class="alert">
                                    <div *ngIf="comment.errors?.['required']">Required.</div>
                                </div>
                            </td>
                            <td class="col-sm-12 col-md-7 col-lg-7 col-xl-8">
                                <igx-card *ngIf="poRequest.por_pk > 0">
                                    <igx-card-header class="igx-card-header">
                                        PORequest History
                                    </igx-card-header>
                                    <igx-card-content class="gradiant">
                                        <div class="mat-elevation-z8">
                                            <div class="grid__wrapper">
                                                <div class="grid__wrapper__inner">
                                                    <igx-grid #gridHistory [igxPreventDocumentScroll]="false"
                                                        [data]="history" height="100%" width="100%"
                                                        [primaryKey]="'poh_pk'" [autoGenerate]="false"
                                                        rowSelection="none" cellSelection="none" [rowEditable]="false"
                                                        hiddenColumnsText="Hidden" [batchEditing]="false">

                                                        <igx-column field="poh_rejectdate" header="Reject Date"
                                                            [dataType]="'date'" [filterable]="false" [sortable]="false"
                                                            [resizable]="true" [editable]="false">
                                                            <ng-template igxCell let-cell="cell" let-val>
                                                                <span>{{ val | date: 'MM-dd-yyyy' }}</span>
                                                            </ng-template>
                                                        </igx-column>
                                                        <igx-column field="poh_rejectreason" header="Reject Reason"
                                                            [dataType]="'string'" [filterable]="false"
                                                            [sortable]="false" [resizable]="true" [editable]="false">
                                                            <ng-template igxCell let-cell="cell">
                                                                <span>{{cell.value}}</span>
                                                            </ng-template>
                                                        </igx-column>
                                                        <igx-column field="poh_rejectby" header="Reject By"
                                                            [dataType]="'string'" [filterable]="false"
                                                            [sortable]="false" [resizable]="true" [editable]="false">
                                                            <ng-template igxCell let-cell="cell">
                                                                <span>{{cell.value}}</span>
                                                            </ng-template>
                                                        </igx-column>

                                                        <ng-template igxHeadSelector let-headContext>
                                                            <div class="header-selector">
                                                            </div>
                                                        </ng-template>

                                                        <ng-template igxRowSelector let-rowContext>
                                                            <div class="row-selector">
                                                            </div>
                                                        </ng-template>
                                                    </igx-grid>
                                                </div>
                                            </div>
                                        </div>
                                    </igx-card-content>
                                </igx-card>
                            </td>
                        </tr>
                    </table>
                </fieldset>
            </igx-card-content>
        </igx-card>
        <igx-card *ngIf="this.poRequest.por_pk > 0">
            <igx-card-header class="igx-card-header">
                Upload File
            </igx-card-header>
            <igx-card-content class="gradiant">
                <table class="mt-2" style="width: 100%;">
                    <tr class="row">
                        <td class="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                            <label igxLabel class="igx-label">Document Path</label>
                        </td>
                        <td class="col-sm-7 col-md-7 col-lg-5 col-xl-4">
                            <igx-input-group class="upload-group"
                                style="display: inline-block;margin-right: 10px;width: calc(100% - 100px);">
                                <input igxInput type="file" [ngModel] #porUpload name="porUpload" id="porUpload"
                                    (change)="onFileSelected($event)" class="igx-input upload-file" accept="image/*"
                                    style="--input-size: var(--ig-size, var(--ig-size-small));--component-size: var(--ig-size, var(--ig-size-small));" />
                            </igx-input-group>
                        </td>
                        <td class="col-sm-5 col-md-5 col-lg-5 col-xl-4 manual-col-width-5">
                            <button igxButton="contained" igxRipple class="col-auto igx-primary-btn"
                                (click)="porUpload.click()">Browse
                            </button>&nbsp;&nbsp;&nbsp;
                            <button igxButton="contained" igxRipple class="igx-primary-btn"
                                (click)="uploadFile($event)">Upload
                                File</button>
                        </td>
                    </tr>
                </table>
                <div class="mat-elevation-z8" style="margin-top:20px;">
                    <div class="grid__wrapper">
                        <div class="grid__wrapper__inner">
                            <igx-grid #gridContractDocumentSharing [igxPreventDocumentScroll]="false"
                                [data]="poDocuemtData" height="100%" width="100%" [primaryKey]="'id'"
                                [autoGenerate]="false" rowSelection="none" cellSelection="none" [rowEditable]="false"
                                hiddenColumnsText="Hidden" [batchEditing]="false">

                                <igx-column field="pot_originalname" header="File Name" [sortable]="true"
                                    [resizable]="true" [dataType]="'string'">
                                    <ng-template igxCell let-cell="cell">
                                        <span>{{cell.value}}</span>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="pot_datecreated" header="Date Uploaded" [filterable]="true"
                                    [sortable]="true" [dataType]="'date'" [pipeArgs]="pipeArgs" [resizable]="true">
                                    <ng-template igxCell let-cell="cell">
                                        <span>{{ cell.value | date: 'MM-dd-yyyy' }}</span>
                                    </ng-template>
                                </igx-column>

                                <ng-template igxHeadSelector let-headContext>
                                    <div class="header-selector">
                                    </div>
                                </ng-template>

                                <ng-template igxRowSelector let-rowContext>
                                    <div class="row-selector">
                                    </div>
                                </ng-template>
                            </igx-grid>
                        </div>
                    </div>
                </div>
            </igx-card-content>
        </igx-card>
        <igx-card [ngClass]="hasVariance('B') === true ? 'hasChildRow' : 'noChildRow'">
            <igx-card-header class="igx-card-header">
                Child Entry
            </igx-card-header>
            <igx-card-content class="gradiant">
                <div class="mat-elevation-z8">
                    <div class="grid__wrapper">
                        <div class="grid__wrapper__inner">
                            <igx-grid #gridChildEntry [igxPreventDocumentScroll]="false" [data]="backCharges"
                                height="100%" width="100%" [primaryKey]="'bkc_pk'" [autoGenerate]="false"
                                rowSelection="single" cellSelection="none" [rowEditable]="false"
                                hiddenColumnsText="Hidden" [batchEditing]="true" (selected)="onCellSelected($event)"
                                (rowSelectionChanging)="handleRowSelection($event)">

                                <!-- <igx-column field="id" header="Id" [hidden]="true"></igx-column> -->
                                <igx-column field="bkc_isreject" header="Rejected?" [sortable]="true" [resizable]="true"
                                    [dataType]="'boolean'" [cellTemplate]="chkReSubmit">
                                    <ng-template #chkReSubmit let-cell="cell">
                                        <igx-checkbox [(ngModel)]="cell.value" [ngModelOptions]="{standalone: true}"
                                            [disabled]="!cell.row.data.bkc_approve || cell.row.data.bkc_po === '' || !(checkoutUser
                                                || isAdministrator || isAssistanceBuilder) || permission.poRequest < rolesEnum.ModifyOnly || house.hou_payrollcompleted">
                                        </igx-checkbox>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="bkc_vetfk" header="Vendor Type" [filterable]="true" [sortable]="true"
                                    [resizable]="true"
                                    [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                    <ng-template igxCell let-cell="cell">
                                        <span>{{vendorTypes | SelectVendorTypeById : cell.value}}</span>
                                    </ng-template>
                                    <ng-template igxCellEditor let-cell="cell" let-value>
                                        <igx-simple-combo [(ngModel)]="cell.value" [ngModelOptions]="{standalone: true}"
                                            [data]="vendorTypes" [displayKey]="'vet_name'" [valueKey]="'vet_pk'">
                                        </igx-simple-combo>
                                    </ng-template>
                                    <ng-template igxHeader let-cell>
                                        <div><span>Vendor Type</span></div>
                                        <igx-simple-combo (selectionChanging)="changeHeaderChildgrid($event)"
                                            [data]="vendorTypes" [displayKey]="'vet_name'" [valueKey]="'vet_pk'">
                                        </igx-simple-combo>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="bkc_confk" header="Vendor" [filterable]="true" [sortable]="true"
                                    [resizable]="true"
                                    [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                    <ng-template igxCell let-cell="cell">
                                        <span>{{getVendorName(cell.value)}}</span>
                                    </ng-template>
                                    <ng-template igxCellEditor let-cell="cell" let-value>
                                        <igx-simple-combo [(ngModel)]="cell.value" [ngModelOptions]="{standalone: true}"
                                            [data]="bkcVendorList" [displayKey]="'con_name'" [valueKey]="'con_pk'">
                                        </igx-simple-combo>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="bkc_ccdfk" header="Cost Code" [filterable]="true" [sortable]="true"
                                    [resizable]="true"
                                    [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                    <ng-template igxCell let-cell="cell">
                                        <span>{{poRequestCostcodeList | SelectCostCodeIdById :
                                            cell.value}}</span>
                                    </ng-template>
                                    <ng-template igxCellEditor let-cell="cell" let-value>
                                        <igx-simple-combo [(ngModel)]="cell.value" [ngModelOptions]="{standalone: true}"
                                            [data]="bkcCostCodeList" [displayKey]="'ccd_id'" [valueKey]="'ccd_pk'">
                                        </igx-simple-combo>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="bkc_ccdfk" header="Cost Code Description" [filterable]="true"
                                    [sortable]="true" [resizable]="true"
                                    [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                    <ng-template igxCell let-cell="cell">
                                        <span>{{poRequestCostcodeList | SelectCostCodeById :
                                            cell.value}}</span>
                                    </ng-template>
                                    <ng-template igxCellEditor let-cell="cell" let-value>
                                        <igx-simple-combo [(ngModel)]="cell.value" [ngModelOptions]="{standalone: true}"
                                            [data]="bkcCostCodeList" [displayKey]="'ccd_name'" [valueKey]="'con_pk'">
                                        </igx-simple-combo>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="bkc_po" header="P.O. #" [dataType]="'string'" [filterable]="true"
                                    [sortable]="true" [resizable]="true"
                                    [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                    <ng-template igxCell let-cell="cell">
                                        <span>{{cell.value}}</span>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="bkc_amount" header="Amount" [dataType]="'number'" [filterable]="true"
                                    [sortable]="true" [resizable]="true"
                                    [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                    <ng-template igxCell let-cell="cell">
                                        <span>{{cell.value}}</span>
                                    </ng-template>
                                    <ng-template igxCellEditor let-cell="cell">
                                        <igx-input-group>
                                            <input igxInput type="number" [(ngModel)]="cell.editValue"
                                                (keydown)="onbkcAmountKeydown($event, cell)" [readonly]="permission.poRequest < rolesEnum.ModifyOnly || !(checkoutUser
                                                || isAdministrator || isAssistanceBuilder)"
                                                [ngModelOptions]="{standalone: true}" />
                                        </igx-input-group>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="bkc_invoice" header="Invoice" [dataType]="'string'"
                                    [filterable]="true" [sortable]="true" [resizable]="true" [editable]="false">
                                    <ng-template igxCell let-cell="cell">
                                        <span>{{cell.value}}</span>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="comment" header="Commnets" [dataType]="'string'" [filterable]="true"
                                    [sortable]="true" [resizable]="true"
                                    [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                    <ng-template igxCell let-cell="cell">
                                        <span>{{cell.value}}</span>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="purchasingCoOrdinatory" header="Purchasing Coordinatory"
                                    [dataType]="'string'" [filterable]="true" [sortable]="true" [resizable]="true"
                                    [editable]="false">
                                    <ng-template igxCell let-cell="cell">
                                        <span>{{cell.value}}</span>
                                    </ng-template>
                                </igx-column>

                                <ng-template igxHeadSelector let-headContext>
                                    <div class="header-selector">
                                    </div>
                                </ng-template>

                                <ng-template igxRowSelector let-rowContext>
                                    <div class="row-selector">
                                    </div>
                                </ng-template>
                            </igx-grid>
                        </div>
                    </div>
                </div>
            </igx-card-content>
        </igx-card>
    </form>
</div>