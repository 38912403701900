import { NumberSymbol } from "@angular/common"

export enum AccessLevel {
    NoAccess = 0,
    ViewOnly = 1,
    ModifyOnly = 2,
    AddModifyOnly = 3,
    FullControl = 4
};

export enum MessageType {
    Warning = 'warning',
    Success = 'success',
    Error = 'error'
};

export interface IHousePermission {
    house: number,
    generalInfo: number,
    documentsUpload: number,
    userFields: number,
    creditDays: number,
    generalNotes: number,
    generalWarrantyInformation: number,
    houseOnHold: number,
    houseFinalCloseDate: number,
    schedule: number,
    scheduleDragDrop: number,
    fax: number,
    faxSendDate: number,
    faxVendorTrades: number,
    pos: number,
    activityList: number
    stage10Activity: number,
    houseActivityDelete: number,
    milestoneActivityIncomplete: number,
    note: number,
    punchList: number,
    payroll: number,
    payrollPending: number,
    payrollSubmitted: number,
    payrollPaid: number,
    autoPayrollPending: number,
    autoPayrollSubmitted: number,
    autoPayrollPaid: number,
    autoPayrollPOSubmitted: Number,
    comments: number,
    poiActivity: number,
    poRequest: number,
    poRequestPurchaseComment:number,
    hoDetails: number,
    hoWarrantyLetter: number,
    hoGallery: number,
    hoWarrantyDocument: number,
    hoClosingDocument: number,
    hoComment: number,
    hoWarrantyNotes: number,
    warrantyLetterPunchList: number,
    sales: number,
    formHouse: number
}