import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SelectVendorTypeById' })
export class SelectVendorTypeById implements PipeTransform {
    transform(value: any[], vet_pk: number): any {
        if (value && value.length > 0) {
            return value.filter(o => {
                return o.vet_pk === vet_pk;
            }).map(o => o.vet_name);
        } else {
            return 'No data found';
        }
    }
}
