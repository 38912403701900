import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class BackChargeService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('back-charge');
    }
    getAll(): Observable<any> {
        return this.http.get(`${this.path}`);
    }
    get(pk: any): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    create(model: any): Observable<any> {
        return this.http.post<any>(`${this.path}`, model);
    }
    getByPORequest(pk: any): Observable<any> {
        return this.http.get(`${this.path}?action=porequest&id=${pk}`);
    }
    bulkInsert(model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}`, model);
    }    
}