// Angular Import
import { Component, Input, OnDestroy, Inject, ViewEncapsulation, ChangeDetectorRef, AfterViewChecked, AfterViewInit, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { DOCUMENT } from '@angular/common';

// project import
import { Spinkit } from './spinkits';
import { LoadingService } from 'src/app/core/services/loader.service';
import { BehaviorSubject } from 'rxjs';
import { Globals } from 'src/globals';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss', './spinkit-css/sk-line-material.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  // public props
  isSpinnerVisible = false;
  Spinkit = Spinkit;
  @Input() backgroundColor = '#2689E2';
  @Input() spinner = Spinkit.skLine;

  public loading = new BehaviorSubject<boolean>(false);
  public loadingIndicator$ = this.loading.asObservable();

  // constructor
  constructor(
    private router: Router,
    private loader: LoadingService,
    private cdRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    console.log(this.router);
    this.router.events.subscribe(
      (event) => {
        console.log(this.router);
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true;
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          this.isSpinnerVisible = false;
        }
      },
      () => {
        this.isSpinnerVisible = false;
      }
    );
  }
  ngAfterViewChecked(): void {
    this.loadingIndicator$ = this.loader.loading$;
    this.cdRef.detectChanges();
  }
  // life cycle event
  ngOnDestroy(): void {
    this.loading.complete();
    this.isSpinnerVisible = false;
  }
  ngAfterViewInit(): void {
  }
  ngOnInit(): void {
  }
}
