import { Component, Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-house-on-hold',
  templateUrl: './house-on-hold.component.html',
  styleUrls: ['./house-on-hold.component.scss']
})
export class HouseOnHoldComponent {
  cardHeader: string = '';
  type: string = '';
  notesObj: any = {};
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<HouseOnHoldComponent>
  ) {
    this.cardHeader = data.cardHeader;
    this.notesObj = data.data;
    this.type = data.type;
  }
  saveData(): void {
    if (this.type === 'householdnote' && this.notesObj.hhn_note !== '' && this.notesObj.hhn_note.length > 0) {
      this.dialogRef.close(this.notesObj);
    }
    else if (this.type === 'housenote' && this.notesObj.hon_note !== '' && this.notesObj.hon_note.length > 0) {
      this.dialogRef.close(this.notesObj);
    }
    else if (this.type === 'creditdays' && this.notesObj.crd_requesteddays !== '' && this.notesObj.crd_requesteddays > 0 && this.notesObj.crd_approveddays !== '' && this.notesObj.crd_approveddays > 0 && this.notesObj.crd_approveddays <= this.notesObj.crd_requesteddays) {
      this.dialogRef.close(this.notesObj);
    }
  }
  close(): void {
    this.dialogRef.close();
  }
}
