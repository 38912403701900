import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SelectCostCodeIdById' })
export class SelectCostCodeIdById implements PipeTransform {
    transform(value: any[], ccd_pk: number): any {
        if (value && value.length > 0) {
            return value.filter(o => {
                return o.ccd_pk === ccd_pk;
            }).map(o => o.ccd_id);
        } else {
            return 'No data found';
        }
    }
}
