import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class PORequestHistoryService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('po-request-history');
    }
    getAll(): Observable<any> {
        return this.http.get(`${this.path}`);
    }
    get(pk: any): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    create(model: any): Observable<any> {
        return this.http.post<any>(`${this.path}`, model);
    }
    getByPORequest(poRequest: any, backCharge: any): Observable<any> {
        if (backCharge && backCharge !== null) {
            return this.http.get(`${this.path}?action=porequest&porequest=${poRequest}&backCharge=${backCharge}`);
        }
        else {
            return this.http.get(`${this.path}?action=porequest&porequest=${poRequest}`);
        }
    }

    bulkInsert(model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}`, model);
    }
}