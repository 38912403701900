import { Injectable } from '@angular/core';
import { ToastType } from './toast.type';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    subject: BehaviorSubject<any>;
    toast$: Observable<any>;

    constructor() {
        this.subject = new BehaviorSubject<any>(null);
        this.toast$ = this.subject.asObservable()
            .pipe(filter(toast => toast !== null));
    }
    
    show(type: ToastType, title: string, body: string, delay: number = 6000) {
        this.subject.next({ type, title, body, delay });
    }
}