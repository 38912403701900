<form class="form-wrapper" id="common-dialog" style="height: 100%;overflow: auto;" [formGroup]="commonForm">
    <igx-card>
        <igx-card-header class="igx-card-header">
            {{ cardHeader | titlecase }}
        </igx-card-header>
        <igx-card-content class="gradiant">
            <table class="mt-2 column" style="width: 100%;height: 100%;">
                <tr class="row">
                    <!-- <td class="col-sm-3 col-md-3 col-lg-3 col-xl-3"> -->
                    <td style="max-width: 31%; width:31%;">
                        <label igxLabel class="igx-label">{{lblName}} :</label>
                    </td>
                    <td class="col-sm-4 col-md-4 col-lg-4 col-xl-8">
                        <div mat-dialog-content>
                            <igx-simple-combo [data]="customList" [displayKey]="'title'" [valueKey]="'value'"
                                placeholder="Select" #cmbCustomList name="cmbCustomList"
                                formControlName="cmbCustomList">
                            </igx-simple-combo>
                            <mat-error *ngIf="hasError('cmbCustomList', 'required')">
                                <!-- Selection is <strong>required</strong> -->
                                Required
                            </mat-error>
                        </div>
                    </td>
                </tr>

                <tr class="row">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-3"> </td>
                    <td class="col-sm-4 col-md-4 col-lg-4 col-xl-9">
                        <div mat-dialog-actions class="d-flex justify-content-end button-sample">
                            <button igxButton="flat" igxRipple (click)="getData()" class="me-2"
                                style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));">Ok</button>
                            <button igxButton="flat" igxRipple (click)="close()"
                                style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1); ">Cancel</button>
                        </div>
                    </td>
                </tr>
            </table>
        </igx-card-content>
    </igx-card>
</form>