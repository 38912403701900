<form class="form-wrapper" id="transfer-houses-dialog" style="height: 100%;overflow: auto;"
    [formGroup]="transferHouseForm">
    <igx-card>
        <igx-card-header class="igx-card-header">
            Transfer House(s)
        </igx-card-header>
        <igx-card-content class="gradiant">
            <table class="mt-3 column">
                <tr class="row">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-4">
                        <label igxLabel class="igx-label">Transfer From:</label>
                    </td>
                    <td class="col-sm-4 col-md-4 col-lg-4 col-xl-8">
                        <div mat-dialog-content>
                            <igx-simple-combo [data]="builderListFrom" [displayKey]="'usr_name'" [valueKey]="'usr_pk'"
                                placeholder="Select Builder" #builderFrom name="builderFrom"
                                (selectionChanging)="changeSelection($event)" formControlName="builderFrom"
                                id="builderFrom">
                            </igx-simple-combo>
                        </div>
                    </td>
                </tr>
                <tr class="row">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-4">
                        <label igxLabel class="igx-label">Company:</label>
                    </td>
                    <td class="col-sm-4 col-md-4 col-lg-4 col-xl-8">
                        <div mat-dialog-content>
                            <igx-simple-combo [data]="companyList" [displayKey]="'com_name'" [valueKey]="'com_pk'"
                                placeholder="Select Company" #companyName name="companyName"
                                (selectionChanging)="changeSelection($event)" formControlName="companyName"
                                id="companyName">
                            </igx-simple-combo>
                        </div>
                    </td>
                </tr>
                <tr class="row">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-4">
                        <label igxLabel class="igx-label">House:</label>
                    </td>
                    <td class="col-sm-4 col-md-4 col-lg-4 col-xl-8">
                        <div mat-dialog-content>
                            <igx-combo class="combo" [(ngModel)]="selectedHouse" [itemsMaxHeight]="250" name="houseName"
                                #houseName [data]="houseList" [displayKey]="'hou_address'" [valueKey]="'hou_pk'"
                                placeholder="Select House" searchPlaceholder="Search..." formControlName="houseName">
                                <ng-template igxComboHeader>
                                    <div class="select-all">
                                        <igx-checkbox class="igx-drop-down__item" [readonly]="true"
                                            [checked]="allSelected" (click)="handleSelect($event)">
                                            {{allSelected ? 'Dese' : 'Se'}}lect all</igx-checkbox>
                                    </div>
                                </ng-template>
                            </igx-combo>

                        </div>
                    </td>
                </tr>
                <tr class="row">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-4">
                        <label igxLabel class="igx-label">Transfer To:</label>
                    </td>
                    <td class="col-sm-4 col-md-4 col-lg-4 col-xl-8">
                        <div mat-dialog-content>
                            <igx-simple-combo [data]="builderListTo" [displayKey]="'usr_name'" [valueKey]="'usr_pk'"
                                placeholder="Select Builder" #builderTo name="builderTo" id="builderTo"
                                formControlName="builderTo">
                            </igx-simple-combo>
                        </div>
                    </td>
                </tr>

                <tr class="row">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-3"> </td>
                    <td class="col-sm-4 col-md-4 col-lg-4 col-xl-9">
                        <div mat-dialog-actions class="d-flex justify-content-end button-sample">
                            <button igxButton="flat" igxRipple (click)="transferHouses()" class="me-2"
                                style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));"
                                [disabled]="!transferHouseForm.valid">Ok</button>
                            <button igxButton="flat" igxRipple (click)="close()"
                                style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1); ">Cancel</button>
                        </div>
                    </td>
                </tr>
            </table>
        </igx-card-content>
    </igx-card>
</form>