import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends ApiService<any> {
  constructor(protected http: HttpClient) {
    super('report');
  }

  completedHouseJobReport(): Observable<any> {
    return this.http.get(`${this.path}/CompletedHouseJobReport`);
  }

  faxByHouseReport(id: number): Observable<any> {
    return this.http.get(`${this.path}/FaxByHouseReport?house=${id}`);
  }
  warrantyLetterActivityCostCodeMappingReport(): Observable<any> {
    return this.http.get(`${this.path}/WarrantyLetterActivityCostCodeMappingReport`);
  }
  CurrentStageBySupervisorReport(user: number, reportId: string, subdivision: number, builder: number, company: number): Observable<any> {
    return this.http.get(`${this.path}/CurrentStageBySupervisorReport?user=${user}&report=${reportId}&subdivision=${subdivision}&builder=${builder}&company=${company}`);
  }
  getPerformanceRankingsBySupervisorReport(subdivision: number | null = null): Observable<any> {
    if (subdivision === null) {
      return this.http.get(`${this.path}/PerformanceRankingsBySupervisorReport?subdivision=`);
    } else {
      return this.http.get(`${this.path}/PerformanceRankingsBySupervisorReport?subdivision=${subdivision}`);
    }
  }
  getPerformanceRankingsByBuilderReport(subdivision: number | null = null): Observable<any> {
    if (subdivision === null) {
      return this.http.get(`${this.path}/PerformanceRankingsByBuilderReport?subdivision=`);
    } else {
      return this.http.get(`${this.path}/PerformanceRankingsByBuilderReport?subdivision=${subdivision}`);
    }
  }
  getDailyActivityExtendedBySupervisorReport(subdivision: number = 0, builder: number = 0, company: number = 0) {
    return this.http.get(`${this.path}/DailyActivityExtendedBySupervisorReport?subdivision=${subdivision}&builder=${builder}&company=${company}`);
  }
  getDailyActivityExtendedByBuilderReport(subdivision: number = 0, builder: number = 0, company: number = 0) {
    return this.http.get(`${this.path}/DailyActivityExtendedByBuilderReport?subdivision=${subdivision}&builder=${builder}&company=${company}`);
  }
  getMilestoneBySupervisorReport() {
    return this.http.get(`${this.path}/MilestoneBySupervisorReport`);
  }
  getMilestoneByWarrantyManagerReport() {
    return this.http.get(`${this.path}/MilestoneByWarrantyManagerReport`);
  }
  getMilestoneByAssistedBuilderReport() {
    return this.http.get(`${this.path}/MilestoneByAssistedBuilderReport`);
  }
  getMilestoneByDesignerReport() {
    return this.http.get(`${this.path}/MilestoneByDesignerReport`);
  }
  getMilestoneByBuilderReport() {
    return this.http.get(`${this.path}/MilestoneByBuilderReport`);
  }
  getDailyActivityBySupervisorReport(subdivision: number = 0, builder: number = 0, company: number = 0) {
    return this.http.get(`${this.path}/DailyActivityBySupervisorReport?subdivision=${subdivision}&builder=${builder}&company=${company}`);
  }
  getDailyActivityByBuilderReport(subdivision: number = 0, builder: number = 0, company: number = 0) {
    return this.http.get(`${this.path}/DailyActivityByBuilderReport?subdivision=${subdivision}&builder=${builder}&company=${company}`);
  }
  getPORequestPOImportBySupervisorReport(): Observable<any> {
    return this.http.get(`${this.path}/PORequestPOImportBySupervisorReport`);
  }
  getFaxByHouseReport(house: number): Observable<any> {
    return this.http.get(`${this.path}/FaxByHouseReport?house=${house}`)
  }
  getAutoPayrollBySupervisorReport(company: number, subdivision: number, house: number): Observable<any> {
    return this.http.get(`${this.path}/AutoPayrollBySupervisorReport?company=${company}&subdivision=${subdivision}&house=${house}`)
  }
  getAutoPayrollByBuilderReport(company: number, subdivision: number, house: number): Observable<any> {
    return this.http.get(`${this.path}/AutoPayrollBySupervisorReport?company=${company}&subdivision=${subdivision}&house=${house}`)
  }
  getHouseActivityBlogBySupervisorReport(company: number, subdivision: number, house: number, startDate: Date, endDate: Date): Observable<any> {
    return this.http.get(`${this.path}/HouseActivityBlogBySupervisorReport?company=${company}&subdivison=${subdivision}&house=${house}&startDate=${startDate}&endDate=${endDate}`)
  }
  getHouseActivityBloglByBuilderReport(company: number, subdivision: number, house: number, startDate: Date, endDate: Date): Observable<any> {
    return this.http.get(`${this.path}/HouseActivityBloglByBuilderReport?company=${company}&subdivison=${subdivision}&house=${house}&startDate=${startDate}&endDate=${endDate}`)
  }

}
