// Angular Import
import { Component, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
// import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  // public props
  menuClass = false;
  collapseStyle = 'none';
  windowWidth = window.innerWidth;
  @Output() NavCollapse = new EventEmitter();
  @Output() NavCollapsedMob = new EventEmitter();

  constructor(
    private router: Router
    // private domSanitizer: DomSanitizer,
    // private matIconRegistry: MatIconRegistry
  ) {
  }
  // public method
  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = this.menuClass ? 'block' : 'none';
  }

  navCollapse() {
    if (this.windowWidth >= 992) {
      // Sumit changes
      // this.NavCollapse.emit();
    }
  }
  // loadIcons(): void {
  //   const icons = [
  //     'home',
  //     'chat',
  //     'arrow',
  //     'arrow_right',
  //     'arrow_left',
  //     'arrow_up',
  //     'info',
  //     'more',
  //     'refresh',
  //     'search',
  //     'close',
  //     'add',
  //     'edit',
  //     'add-outline',
  //     'doc-outline',
  //     'folder-outline',
  //     'settings-outline',
  //     'user',
  //     'logout',
  //     'group',
  //     'add-document',
  //     'add-calendar',
  //     'copy_plan',
  //     'print',
  //     'save',
  //   ];
  //   icons.forEach((icon) => {
  //     if (!icon) {
  //       return;
  //     }
  //     try {
  //       this.matIconRegistry.addSvgIcon(
  //         icon,
  //         this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${icon}.svg`)
  //       );
  //     } catch (e) {
  //       throw Error('The icon was not added');
  //     }
  //   });
  // }
  public signOut(): void {
    // this.cognitoService.signOut()
    //   .then(() => {
    //     this.router.navigate(['/login']);
    //   }).catch((err: any) => {
    //   });
  }
}
