import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { LocalService } from './local.service';

@Injectable({
    providedIn: 'root'
})
export class PolicyService extends ApiService<any> {
    constructor(protected http: HttpClient,
        private localStorage: LocalService
    ) {
        super('policy');
    }

    get(pk: any): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    getByUser(): Observable<any> {
        return this.http.get(`${this.path}?action=user`);
    }
    getByGroup(group: any): Observable<any> {
        return this.http.get(`${this.path}?action=group&group=${group}`);
    }
    bulkInsert(models: any[]): Observable<any> {
        this.localStorage.removeData('policy');
        return this.http.post(`${this.path}`, models);
    }
    bulkUpdate(models: any[]): Observable<any> {
        this.localStorage.removeData('policy');
        return this.http.put(`${this.path}`, models);
    }
    copy(targetGroup: number, sourceGroup: number): Observable<any> {
        this.localStorage.removeData('policy');
        return this.http.post(`${this.path}/copy?targetGroup=${targetGroup}&sourceGroup=${sourceGroup}`, '');
    }
}