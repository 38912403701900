import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-toaster',
    templateUrl: './toaster.component.html',
    styleUrls: ['./toaster.component.scss']
})
export default class ToasterComponent {
    @Input() toast: any;
    @Input() i: any;

    @Output() remove = new EventEmitter<any>();
    delete(index: any): any {
        this.remove.emit(index);
    }
}
