import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'SelectGroupById' })
export class SelectGroupById implements PipeTransform {
    transform(value: any[], groupId: number): any {
        if (value && value.length > 0) {
            return value.filter(o => {
                return o.grp_pk === groupId;
            }).map(o => o.grp_name);
        } else {
            return '';
        }
    }
}