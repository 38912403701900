import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class ContactService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('contact');
    }
    getAll(action: any, user: any = null): Observable<any> {
        if (user) {
            return this.http.get(`${this.path}?action=${action}&user=${user}`);
        }
        else {
            return this.http.get(`${this.path}?action=${action}`);
        }
    }
    getBySubdivisionByUser(action: string, subdivision: number, user: number): Observable<any> {
        return this.http.get(`${this.path}?action=${action}&subdivision=${subdivision}&user=${user}`);
    }
    get(pk: any): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    getRecall(pageIndex: any, pageSize: any): Observable<any> {
        return this.http.get(`${this.path}?action=recall&pageIndex=${pageIndex}&pageSize=${pageSize}`);
    }
    getByCodeByCompany(company: number, code: number): Observable<any> {
        return this.http.get(`${this.path}?action=select&company=${company}&code=${code}`);
    }
    getBySubdivision(subdivision: any): Observable<any> {
        return this.http.get(`${this.path}?action=subdivision&subdivision=${subdivision}`);
    }
    SelectVendorByCostCode(costCodeId: number): Observable<any> {
        return this.http.get(`${this.path}/SelectVendorByCostCode?costCode=` + costCodeId)
    }
    getVendorTypeBySubdivision(subdivision: any): Observable<any> {
        return this.http.get(`${this.path}?action=vendortypesubdivision&subdivision=${subdivision}`);
    }
    recallContact(model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}/recall`, model);
    }
    getBySubdivisionOperation(subdivision: any, user: any): Observable<any> {
        return this.http.get(`${this.path}?action=operationbysubdivision&subdivision=${subdivision}&user=${user}`);
    }
    bulkUpdate(model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}?action=bulk`, model);
    }
    update(id: number, model: any) {
        return this.http.put(`${this.path}/${id}`, model);
    }
    create(model: any): Observable<any> {
        return this.http.post<any>(`${this.path}`, model);
    }
    copyActivities(action: string, sourceContact: number, targetContact: number): Observable<any> {
        return this.http.post<any>(`${this.path}/copy?action=${action}&sourceContact=${sourceContact}&targetContact=${targetContact}`, '');
    }
    copyVendorType(action: string, sourceContact: number, targetContact: number): Observable<any> {
        return this.http.post<any>(`${this.path}/copy?action=${action}&sourceContact=${sourceContact}&targetContact=${targetContact}`, '');
    }
    delete(id: number) {
        return this.http.delete<any>(`${this.path}/${id}`);
    }
    canDeleteContact(contact: number): Observable<any> {
        return this.http.get(`${this.path}/CanContactDelete/${contact}`);
    }
}