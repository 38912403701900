<form class="form-wrapper" id="set-password-dialog" style="height: 100%;overflow: auto;"
    [formGroup]="setPasswordForm">
    <igx-card>
        <igx-card-header class="igx-card-header">
           Set Password
        </igx-card-header>
        <igx-card-content class="gradiant">
            <table class="mt-3 column" >
                <tr class="row">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-4">
                        <label igxLabel class="igx-label">New Password:</label>
                    </td>
                    <td class="col-sm-4 col-md-4 col-lg-4 col-xl-8">
                        <div mat-dialog-content>
                            <igx-input-group>
                                <input igxInput #contactText class="igx-input"
                                     name="contactText"
                                     type="text" />
                            </igx-input-group>
                        </div>
                    </td>
                </tr>
                <tr class="row">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-4">
                        <label igxLabel class="igx-label">Confirm Password:</label>
                    </td>
                    <td class="col-sm-4 col-md-4 col-lg-4 col-xl-8">
                        <div mat-dialog-content>
                            <igx-input-group>
                                <input igxInput #contactText class="igx-input"
                                     name="contactText"
                                     type="text" />
                            </igx-input-group>
                        </div>
                    </td>
                </tr>

                <tr class="row">
                    <td class="col-sm-3 col-md-3 col-lg-3 col-xl-3"> </td>
                    <td class="col-sm-4 col-md-4 col-lg-4 col-xl-9">
                        <div mat-dialog-actions class="d-flex justify-content-end button-sample">
                            <button igxButton="flat" igxRipple (click)="close()"  class="me-2"
                                style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));">Ok</button>
                            <button igxButton="flat" igxRipple (click)="close()"
                                style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1); ">Cancel</button>
                        </div>
                    </td>
                </tr>
            </table>
        </igx-card-content>
    </igx-card>
</form>